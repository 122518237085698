import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useStore = create(
  persist(
    set => ({
      payload: null,
      isOpen: false,
      reportpayload: null,
      finalizeReport: null,
      imageOverlayProps: null,

      // reportFormValues: {
      //   studyInstanceUID: '',
      //   reportData: [],
      //   selectedReport: null,
      //   rightPanelClosedState: true,
      //   reportType: '',
      //   isDropdownOpen: false,
      // },

      setPayload: newpayload => set({ payload: newpayload }),
      setIsOpen: newisOpen => set({ isOpen: newisOpen }),
      setReportPayload: newReppayload => set({ reportpayload: newReppayload }),
      setFinalizeReport: newFinalizeReport => set({ finalizeReport: newFinalizeReport }),
      setImageOverlayProps: newImageOverlayProps => set({ imageOverlayProps: newImageOverlayProps }),

      // setReportFormValues: (values) =>
      //   set((state) => ({
      //     reportFormValues: { ...state.reportFormValues, ...values },
      //   })),
      // clearReportFormValues: () =>
      //   set(() => ({
      //     reportFormValues: {
      //       studyInstanceUID: '',
      //       reportData: [],
      //       selectedReport: null,
      //       rightPanelClosedState: true,
      //       reportType: '',
      //       isDropdownOpen: false,
      //     },
      //   })),
      // setRightPanelClosed: (closedState) =>
      //   set((state) => ({
      //     reportFormValues: {
      //       ...state.reportFormValues,
      //       rightPanelClosedState: closedState,
      //     },
      //   })),

      // setIsDropdownOpen: (isOpen) =>
      //   set((state) => ({
      //     reportFormValues: {
      //       ...state.reportFormValues,
      //       isDropdownOpen: isOpen,
      //     },
      //   })),

      // setSelectedReport: (report) =>
      //   set((state) => ({
      //     reportFormValues: {
      //       ...state.reportFormValues,
      //       selectedReport: report,
      //     },
      //   })),
    }),
    {
      name: 'my-shared-store',
      partialize: state => ({ reportpayload: state.reportpayload }),
      // reportFormValues: state.reportFormValues
    }
  )
);

export default useStore;
