import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import useStore from '../../../../uiState';

function CRReportForm({
  onSave,
  studyInstanceUID,
  placeholder,
  reportData,
  selectedReport,
  rightPanelClosedState,
  setRightPanelClosed,
  onClose,
  onFinzaliedSave,
  onRemove,
  reportType,
}) {
  const [showForm, setShowForm] = useState(true);
  const [content, setContent] = useState('Start typing...');
  const editor = useRef(null);
  const [clientID, setClientID] = useState();
  const [appUserID, setAppUserID] = useState();
  const [templateID, setTemplateID] = useState();
  const [patientName, setPatientName] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const isFinalized = reportType == 'Finalized';
  const finalizeReport = useStore(state => state.finalizeReport);

  useEffect(() => {
  }, [reportType, finalizeReport, selectedReport]);


  useEffect(() => {
    const repdata = reportData[0];
    setClientID(repdata.clientId);
    setAppUserID(repdata.appUserId);
    setPatientName(repdata.name);

    const reportTemplate = selectedReport?.report;
    setTemplateID(selectedReport?.id);

    const safeData = data => (data != null && data != undefined ? data : '');

    const formatDate = dateString => {
      if (!dateString) {
        return '';
      }
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const currentDate = formatDate(new Date());

    let tableContent;
    if (!selectedReport?.reportId) {
      tableContent = `
        <table class="table text-lg no-edit" style="width: 100%; border: 1px solid #000000;" contenteditable="false">
          <tbody>
            <tr>
              <th style="text-align: left">Name</th>
              <td style="text-align: left">${safeData(repdata.name)}</td>
              <th style="text-align: left">Patient ID</th>
              <td style="text-align: left">${safeData(repdata.patientId)}</td>
            </tr>
            <tr>
              <th style="text-align: left">Accession No</th>
              <td style="text-align: left">${safeData(repdata.accession)}</td>
              <th style="text-align: left">Date</th>
              <td style="text-align: left">${currentDate}</td>
            </tr>
            <tr>
              <th style="text-align: left">Age/Gender</th>
              <td style="text-align: left">${safeData(repdata.age)} / ${safeData(repdata.gender)}</td>
              <th style="text-align: left">Center Name</th>
              <td style="text-align: left">${safeData(repdata.center)}</td>
            </tr>
            <tr>
              <th style="text-align: left">Study Date</th>
              <td style="text-align: left">${formatDate(repdata.studyDate)}</td>
               <th style="text-align: left">Modality</th>
              <td style="text-align: left">${safeData(repdata.modality)}</td>
            </tr>
            <tr>
              <th style="text-align: left">Reported Time</th>
              <td style="text-align: left" id="reportedTime"><br></td>
              <th style="text-align: left">Reported by</th>
              <td style="text-align: left"></strong> ${safeData(repdata.assignedTo)}</td>
            </tr>
          </tbody>
        </table>
        <p class="text-lg">${reportTemplate}</p>
        <p class="text-lg">Reported and Electronically Signed by: <span style="text-align: right" id="signBy"><br></span></p>
        <p class="text-lg"><strong>Reported by:</strong> ${safeData(repdata.assignedTo)}</p>
        <p class="text-lg"><strong>Date Reported:</strong>${currentDate}</p>
      `;
    } else {
      tableContent = reportTemplate;
    }
    setContent(tableContent);
  }, [selectedReport, reportData]);

  const handleChange = newContent => {
    setContent(newContent);
  };

  const handleSave = async () => {
    const iswindowOpen: boolean = false;
    await onSave(patientName, content, appUserID, clientID, templateID, iswindowOpen);
    setRightPanelClosed(true);
    onClose();
  };

  const config = useMemo(
    () => ({
      readonly: isFinalized,
      placeholder: placeholder || 'Start typing...',
    }),
    [placeholder]
  );


  const handleSaveAndFinalize = async () => {
    const currentTime = new Date().toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });

    const regexPattern = /(<td\s+style="text-align:\s*left"\s+id="reportedTime">\s*)(<br>\s*)(<\/td>)/i;

    let updatedContent = content.replace(regexPattern, `$1${currentTime}$3`);

    const signPattern = /(<span\s+style="text-align:\s*right"\s+id="signBy">)(<br\s*\/?>\s*)(<\/span>)/i;
    updatedContent = updatedContent.replace(signPattern, `$1@sign@$3`);

    // console.log("Updated Content:", updatedContent);

    setContent(updatedContent);

    const iswindowOpen = false;
    await onSave(patientName, updatedContent, appUserID, clientID, templateID, iswindowOpen);
    onFinzaliedSave();
    setRightPanelClosed(true);
    onClose();
  };

  const openReportInNewTb = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const iswindowOpen: boolean = true;
    onSave(patientName, content, appUserID, clientID, templateID, iswindowOpen);
    setRightPanelClosed(true);
    localStorage.setItem('refreshNeeded', 'true');
  };

  const handleRemoveReport = async () => {
    onRemove(patientName, content, appUserID, clientID, templateID);
    setRightPanelClosed(true);
    onClose();
  };

  const handleCloseReport = async () => {
    setIsDialogOpen(false);
    setRightPanelClosed(true);
    onClose();
  };

  const deletefooterContent = (
    <div className=" my-2 mx-2">
      <Button
        label="No"
        className="bg-customblue-30 text p-button-text mx-4 px-3 py-2 text-white"
        icon="pi pi-times"
        onClick={handleCloseReport}
      />
      <Button
        label="Yes"
        className="text bg-green-600 px-3 py-2 text-white"
        icon="pi pi-check"
        onClick={handleRemoveReport}
        autoFocus
      />
    </div>
  );

  return (
    showForm && (
      <>
        <div className=" reportEditor">
          <div
            className="rounded border bg-white p-2"
            style={{ height: '100%', resize: 'vertical' }}
          >
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              onBlur={handleChange}
            />

            <div className="p-grid p-justify-between">
              <div className="editorBtnContainer mt-2 flex flex-row">
                <button
                  type="submit"
                  onClick={handleSave}
                  className={`mr-2 rounded p-2 text-lg font-normal ${isFinalized
                    ? 'cursor-not-allowed bg-gray-400 font-bold text-black'
                    : 'bg-green-600 text-white'
                    }`}
                  disabled={isFinalized}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={handleSaveAndFinalize}
                  className={`mr-2 rounded p-2 text-lg font-normal ${isFinalized
                    ? 'cursor-not-allowed bg-gray-400 font-bold text-black'
                    : 'bg-green-700 text-white'
                    }`}
                  disabled={isFinalized}
                >
                  Save & Finalize
                </button>

                <button
                  type="button"
                  onClick={() => setIsDialogOpen(true)}
                  className="mr-2 rounded bg-red-400 p-2 text-lg font-normal text-white"
                >
                  Remove
                </button>

                <button
                  type="button"
                  onClick={onClose}
                  className=" mr-2 rounded bg-red-400 p-2 text-lg font-normal text-white"
                >
                  Close
                </button>
              </div>

              {!isFinalized && (
                <div className="mt-2 flex flex-row">
                  <a
                    href={
                      window.location.origin +
                      `/reportviewer?${new URLSearchParams({
                        StudyInstanceUIDs: studyInstanceUID,
                      }).toString()}`
                    }
                    className="mr-2 rounded text-lg font-bold text-blue-600 underline"
                    target="_blank"
                    onClick={openReportInNewTb}
                    rel="noreferrer"
                  >
                    Open in New Tab
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* image delete confirmation  */}
        <Dialog
          header="Confirmation"
          visible={isDialogOpen}
          style={{
            width: '30vw',
            backgroundColor: '#000712',
            color: 'white',
            margin: '10px',
            border: '1px solid #ffffff',
          }}
          onHide={() => setIsDialogOpen(false)}
          footer={deletefooterContent}
        >
          <p className="m-4">Are you sure you want to Remove Report ??</p>
        </Dialog>
      </>
    )
  );
}

CRReportForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  studyInstanceUID: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  reportData: PropTypes.array.isRequired,
  selectedReport: PropTypes.object,
  rightPanelClosedState: PropTypes.bool,
  setRightPanelClosed: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onFinzaliedSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
};

export default CRReportForm;
